.experience__container{
    grid-template-columns: 800px;
    justify-content: center;
    column-gap: 1.8rem;
}

.experience__content{
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--container-color);
    padding: 3rem 0 2rem 2.5rem;

}

.experience__header{
    display: flex;
    column-gap: 1rem; /* Adds space between the icon and the title */
}

.experience__icon{
    display: block;
    font-size: 1.2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-1);
}

.experience__title{
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
}

.experience__button{
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
}

.experience__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}

.experience__button:hover .experience__button-icon{
    transform: translateX(0.25rem);
}

.experience__modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
}


.experience__modal-close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.experience__modal-title,
.experience__modal-description{
    text-align: center;
}

.experience__modal-title{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.experience__modal-time{
    display: flex;
    font-size: var(--small-font-size);
    padding: 0 2rem;
    margin-bottom: var(--mb-2);
}

.experience__modal-experience{
    row-gap: 0.735rem;
}

.experience__modal-project{
    display: flex;
    align-items: flex-start;
    column-gap: 0.5rem;
}

.experience__modal-icon{
    color: var(--title-color);
    font-size: 1.1rem;
}

.experience__modal-info{
    font-size: var(--small-font-size);
    padding: 0 1.5rem 0 0;
}

/*Active modal*/
.active-modal{
    opacity: 1;
    visibility: visible;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .experience__container{
        grid-template-columns: repeat(1, 600px);
    }

}

/* For medium devices */
@media screen and (max-width: 768px) {
    .experience__container{
        grid-template-columns: repeat(1, 1fr);
    }

    .experience__content{
        padding: 3.5rem 0.5rem 1.25rem 1.5rem;
    }

    .experience__icon{
        font-size: 1.5rem;
    }

}

@media screen and (max-width: 576px) {
    .experience__modal-content{
        padding:0rem 0.5rem 0rem;
    }

    .experience__modal-description{
        padding: 0;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .experience__container{
        grid-template-columns: repeat(1, 1fr);
    }

    .experience__content{
        padding-right: 0.5rem;
    }
}


